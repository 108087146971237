<template>
  <main id="TermsOfUse">
    <div class="row">
      <article>
        <header>
          <h2>MYii網站使用條款</h2>
        </header>
        <div class="content">
          <ol>
            <li>
              <p>一、資料的保護與安全</p>
              <ol>
                <li>
                  （1）本網站主機均設有防火牆、防毒系統等相關資訊安全設備及必要的安全防護措施，本網站及您的個人資料均受到嚴格的保護。只有經過授權的人員才能接觸您的個人資料，相關處理人員均有簽署保密合約，如有違反保密義務者，將會受到相關的法律處分。
                </li>
                <li>
                  （2）
                  如因業務需要有必要委託本網站相關單位提供服務時，我們會要求其遵守保密義務，並採取相當之檢查程序以確定其將確實遵守。
                </li>
                <li>
                  （3）請您妥善保管您的密碼與個人資料，並提醒您使用完畢本網站相關服務後，務必關閉本網站，以免您的資料遭人盜用。
                </li>
                <li>
                  （4）您同意在使用本網站服務時，所留存的資料與事實相符。另若嗣後您發現您的個人資料遭他人非法使用或有任何異常時，應即時通知我們。
                </li>
                <li>
                  （5）您同意於使用本網站服務時，所提供及使用之資料皆為合法，並無侵害第三人權利、違反第三方協議或涉及任何違法行為。
                </li>
              </ol>
            </li>
            <li>
              <p>
                二、對外的相關連結本網站上有可能包含其他合作網站或網頁連結，該網站或網頁也有可能會蒐集您的個人資料，不論其內容或隱私權政策為何，皆與本網站無關，您應自行參考該連結網站中的隱私權保護政策，我們不負任何連帶責任。
              </p>
            </li>
            <li>
              <p>三、與第三人共用個人資料之政策</p>
              <ol>
                <li>
                  （1）本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
                </li>
                <li>
                  （2）前項但書之情形包括但不限於：
                  <ol>
                    <li>• 經由您書面同意。</li>
                    <li>• 法律明文規定。</li>
                    <li>• 為維護國家安全或增進公共利益。</li>
                    <li>• 為免除您生命、身體、自由或財產上之危險。</li>
                    <li>
                      •
                      與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。
                    </li>
                    <li>
                      •
                      當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
                    </li>
                    <li>• 有利於您的權益。</li>
                  </ol>
                </li>
                <li>
                  （3）本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
                </li>
              </ol>
            </li>
            <li>
              <p>
                四、未成年人保護未成年人於註冊或使用本服務而同意本公司蒐集、利用其個人資訊時，應按其年齡由其法定代理人代為或在法定代理人之同意下為之。
              </p>
            </li>
            <li>
              <p>
                五、隱私權政策的修訂我們將因應需求擁有隨時修改本隱私權保護政策的權利，當我們做出修改時，會於本網站公告，且自公告日起生效，不再另行通知。請於上班時間聯絡：04-2471-9799或詢問官方LINE，將有專人服務。
              </p>
            </li>
          </ol>
        </div>
      </article>
    </div>
    <Footer />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import Footer from '@/components/MainFooter';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'TermsOfUse',
  mixins: [SmoothScrollResister],
  components: {
    Footer,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  methods: {},
  mounted() {
    this.RegisterGsap('TermsOfUse');
    window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData('使用條款', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
